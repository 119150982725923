import Flyers from './flyers';
import GoogleBusiness from './google-business';
import GoogleReviewProgram from './google-review-program';
import LoyaltyProgram from './loyalty-program';
import PromoCodes from './promo-codes';

export default {
  Flyers,
  GoogleBusiness,
  GoogleReviewProgram,
  LoyaltyProgram,
  PromoCodes,
};
