import {
  FULFILLMENT_STATUSES,
  ORDER_METHODS, ORDER_PAYMENT_STATUSES,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../../services/exports/Constants';
import { action_create_dummy_order, action_launch_orders_portal_app } from '../../../../../../views/sales/orders';

export default {
  title: 'Bestellungen',
  buttons: {
    actions: 'Aktionen',
    [action_create_dummy_order]: 'Testauftrag erstellen',
    [action_launch_orders_portal_app]: 'Start des Bestell-Portal',
  },
  form: {
    user_details: {
      placeholder: 'Kundenname oder Telefonnummer eingeben',
    },
  },
  labels: {
    no_orders: 'Noch keine Bestellungen',
    total_orders: 'Bestellungen gesamt',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ORDER_METHODS.room_service]: {
      [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
      [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
    },
    [ORDER_METHODS.dine_in]: 'Dine in',
  },
  payment_statuses: {
    [ORDER_PAYMENT_STATUSES.pending]: 'Ausstehend',
    [ORDER_PAYMENT_STATUSES.partially_paid]: 'Teilweise bezahlt',
    [ORDER_PAYMENT_STATUSES.paid]: 'Bezahlt',
    [ORDER_PAYMENT_STATUSES.partially_refunded]: 'Teilweise erstattet',
    [ORDER_PAYMENT_STATUSES.refunded]: 'Erstattet',
  },
  fulfillment_statuses: {
    [ORDER_METHODS.dine_in]: {
      [FULFILLMENT_STATUSES.created]: 'Ausstehend',
    },
    default: {
      [FULFILLMENT_STATUSES.created]: 'Bestätigung ausstehend',
      [FULFILLMENT_STATUSES.reviewed]: 'Bestätigung ausstehend',
      [FULFILLMENT_STATUSES.accepted]: 'In Bearbeitung',
      [FULFILLMENT_STATUSES.preparing]: 'In Bearbeitung',
      [FULFILLMENT_STATUSES.ready]: 'In Bearbeitung',
      [FULFILLMENT_STATUSES.on_delivery]: 'In Bearbeitung',
      [FULFILLMENT_STATUSES.picked]: 'Abgeschlossen',
      [FULFILLMENT_STATUSES.rejected]: 'Abgelehnt',
      null: 'Unbekannt',
      undefined: 'Unbekannt',
    },
  },
  tags: {
    dummy: 'SCHNULLER',
    win_order: 'WINORDER',
    pickup: 'Abholung',
    internal_delivery: 'Interne Lieferung',
    external_delivery: 'Externe Lieferung',
    dine_in: 'Dine in',
    room_service: 'Zimmerservice',
    table_service: 'Tischservice',
    scheduled: 'Geplant',
    reward: 'Prämie',
    mobile: 'Mobile',
    cash: 'Cash',
    rejected_automatically: 'Auto abgelehnt',
    rejected_manually: 'Manuell abgelehnt',
  },
  fields: {
    id: 'ID',
    company: 'Restaurant',
    tags: 'Tags',
    order_placed: 'Bestellung aufgegeben',
    customer: 'Kunde',
    items: 'Artikel',
    method: 'Methode',
    payment_status: 'Zahlungsstatus',
    fulfillment_status: 'Status',
    total: 'Gesamt',
  },
  metrics: {
    total_orders: 'Bestellungen insgesamt',
    total_sales: 'Gesamtumsatz',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_create_dummy_order: 'Testauftrag konnte nicht erstellt werden',
    dummy_order_created: 'Testauftrag wurde erstellt',
    failed_to_launch_orders_portal_app: 'Das Auftragsportal konnte nicht gestartet werden',
  },
};
