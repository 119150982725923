export default {
  table: 'Table',
  customer_one: 'Single person',
  customer_other: '{{count}} people in this group',
  left_to_pay: 'left to pay',
  total_order: 'total order',
  amount_refunded: '{{amount}} refunded',
  sections: {
    payments: {
      title: 'Paid',
      fields: {
        time: 'Time',
        tip: 'Tip',
        total: 'Total',
      },
    },
    orders: {
      title: 'Orders',
    },
    cancelled_orders: {
      title: 'Cancelled orders',
      labels: {
        cancelled: 'Cancelled',
      },
    },
  },
};
