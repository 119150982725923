import { ORDER_METHODS } from '../../../../../services/exports/Constants';

export default {
  empty_list: 'No modifier groups yet. Create a new one.',
  fields: {
    promotable: 'Promotable',
    name: 'Name',
    prices: 'Prices',
    available: 'Available',
  },
  order_method_prices: {
    base: 'Base',
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ORDER_METHODS.room_service]: 'Room/Table service',
    [ORDER_METHODS.dine_in]: 'Dine in',
  },
  labels: {
    visible: 'Visible in menu',
    hidden: 'All hidden',
  },
  buttons: {
    add_modifier: 'Add modifier',
    delete_modifier_one: 'Delete modifier',
    delete_modifier_other: 'Delete modifiers',
    show_modifier_group: 'Show modifier group',
    close_modifier_group: 'Close modifier group',
    edit: 'Edit',
    duplicate: 'Duplicate',
  },
  toasts: {
    failed_to_duplicate_modifier_group: 'Failed to duplicate modifier group',
    modifier_group_duplicated_successfully: 'Modifier group duplicated successfully',
    failed_to_duplicate_modifier: 'Failed to duplicate modifier',
    modifier_duplicated_successfully: 'Modifier duplicated successfully',
  },
};
