import { DeliveryStatus } from '../../../../../../services/exports/Constants';

export default {
  title: 'External deliveries',
  fields: {
    pickup: 'Pickup',
    dropoff: 'Dropoff',
    status: 'Status',
    estimated_time: 'Estimated time',
    actual_time: 'Actual time',
    actions: 'Actions',
    time: 'Time',
    pickup_estimate: 'Pickup estimate',
    dropoff_estimate: 'Dropoff estimate',
  },
  labels: {
    pickup: 'Pickup',
    dropoff: 'Dropoff',
  },
  delivery_statuses: {
    [DeliveryStatus.PendingAssign]: 'Pending driver',
    [DeliveryStatus.PendingMerchant]: 'Pending driver',
    [DeliveryStatus.Scheduled]: 'Pending driver',
    [DeliveryStatus.RunnerAssigned]: 'Driver assigned',
    [DeliveryStatus.EnRoutePickup]: 'On route to pickup',
    [DeliveryStatus.ArrivedPickup]: 'Arrived at pickup',
    [DeliveryStatus.PickedUp]: 'Picked up',
    [DeliveryStatus.EnRouteDropoff]: 'On route to dropoff',
    [DeliveryStatus.ArrivedDropoff]: 'Arrived at dropoff',
    [DeliveryStatus.Completed]: 'Completed',
    [DeliveryStatus.Cancelled]: 'Cancelled',
  },
  buttons: {
    actions: 'Actions',
    open_tracking_url: 'Track delivery',
    show_order_details: 'Show order details',
    call_restaurant: 'Call restaurant',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
