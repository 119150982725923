export default {
  fields: {
    discount: {
      label: 'Rabatt',
    },
    usage_threshold: {
      label: 'Mindestbestellwert',
    },
  },
};
