import React from 'react';

export default function UserIcon({
  width,
  height,
  color,
  className,
  style,
}) {
  const _width = width ?? 17;
  const _height = height ?? _width;
  const _color = color ?? 'black';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M8.74477 7.72947C7.61111 7.72947 6.68358 7.36876 5.96216 6.64734C5.24074 5.92593 4.88003 4.99839 4.88003 3.86473C4.88003 2.73108 5.24074 1.80354 5.96216 1.08213C6.68358 0.360709 7.61111 0 8.74477 0C9.87842 0 10.806 0.360709 11.5274 1.08213C12.2488 1.80354 12.6095 2.73108 12.6095 3.86473C12.6095 4.99839 12.2488 5.92593 11.5274 6.64734C10.806 7.36876 9.87842 7.72947 8.74477 7.72947ZM0.5 16V13.5781C0.5 12.9254 0.663178 12.3671 0.989533 11.9034C1.31589 11.4396 1.73671 11.0875 2.25201 10.847C3.40284 10.3317 4.50644 9.94525 5.5628 9.6876C6.61916 9.42995 7.67982 9.30113 8.74477 9.30113C9.80972 9.30113 10.8661 9.43425 11.9138 9.70048C12.9616 9.96672 14.0609 10.3489 15.2118 10.847C15.7442 11.0875 16.1736 11.4396 16.5 11.9034C16.8264 12.3671 16.9895 12.9254 16.9895 13.5781V16H0.5ZM2.04589 14.4541H15.4436V13.5781C15.4436 13.3033 15.3621 13.0413 15.1989 12.7923C15.0357 12.5432 14.8339 12.3586 14.5934 12.2383C13.4941 11.7059 12.4893 11.3408 11.5789 11.1433C10.6685 10.9458 9.72383 10.847 8.74477 10.847C7.7657 10.847 6.8124 10.9458 5.88486 11.1433C4.95733 11.3408 3.9525 11.7059 2.87037 12.2383C2.6299 12.3586 2.43237 12.5432 2.27778 12.7923C2.12319 13.0413 2.04589 13.3033 2.04589 13.5781V14.4541ZM8.74477 6.18358C9.41465 6.18358 9.9686 5.96457 10.4066 5.52657C10.8446 5.08857 11.0636 4.53462 11.0636 3.86473C11.0636 3.19485 10.8446 2.6409 10.4066 2.2029C9.9686 1.7649 9.41465 1.54589 8.74477 1.54589C8.07488 1.54589 7.52093 1.7649 7.08293 2.2029C6.64493 2.6409 6.42593 3.19485 6.42593 3.86473C6.42593 4.53462 6.64493 5.08857 7.08293 5.52657C7.52093 5.96457 8.07488 6.18358 8.74477 6.18358Z"
        fill={_color}
      />
    </svg>
  );
}
