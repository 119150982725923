export default {
  title: 'Check address serviceability',
  or: 'or',
  form: {
    company: {
      label: 'Select restaurant',
    },
    address: {
      label: 'Type in the address',
    },
  },
  result: {
    positive: '{{address}} is eligible for external delivery',
    negative: '{{address}} is not eligible for external delivery',
  },
  buttons: {
    check: 'Check',
  },
  toasts: {
    error: 'Failed to check address serviceability',
  },
};
