import { PAYMENT_METHODS } from '../../../../../services/exports/Constants';

export default {
  sections: {
    recent_orders: 'Recent orders',
  },
  fields: {
    phone_number: 'Phone',
    email: 'Email',
    lifetime_spent: 'Lifetime spent',
    lifetime_points: 'Lifetime points',
    total_orders: 'Total orders',
    created_at: 'Member since',
  },
  payment_methods: {
    [PAYMENT_METHODS.CARD]: 'Paid by card',
    [PAYMENT_METHODS.PAYPAL]: 'Paid by PayPal',
    [PAYMENT_METHODS.APPLE_PAY]: 'Paid by Apple Pay',
    [PAYMENT_METHODS.GOOGLE_PAY]: 'Paid by Google Pay',
    [PAYMENT_METHODS.SOFORT]: 'Paid by Sofort',
    [PAYMENT_METHODS.GIROPAY]: 'Paid by Giropay',
    [PAYMENT_METHODS.SEPA_DEBIT]: 'Paid by Bank debit',
    [PAYMENT_METHODS.CASH]: 'Paid with cash',
    [PAYMENT_METHODS.BACS_DEBIT]: 'Paid by Bank debit',
  },
  labels: {
    order_id: 'Order id',
    items_one: '1 item',
    items_other: '{{count}} items',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
