import { ORDER_METHODS } from '../../../../../services/exports/Constants';

export default {
  empty_list: 'Noch keine Modifizierergruppe. Erstelle Modifizierergruppe.',
  fields: {
    promotable: 'Promotable',
    name: 'Name',
    prices: 'Preise',
    available: 'Verfügbar',
  },
  order_method_prices: {
    base: 'Basis',
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ORDER_METHODS.room_service]: 'Tisch/Zimmer-service',
    [ORDER_METHODS.dine_in]: 'Dine in',
  },
  labels: {
    visible: 'Sichtbar im Menü',
    hidden: 'Alle versteckt',
  },
  buttons: {
    add_modifier: 'Modifizierer hinzufügen',
    delete_modifier_one: 'Modifizierer löschen',
    delete_modifier_other: 'Modifizierer löschen',
    show_modifier_group: 'Modifizierergruppe anzeigen',
    close_modifier_group: 'Modifizierergruppe schließen',
    edit: 'Bearbeiten',
    duplicate: 'Duplizieren',
  },
  toasts: {
    failed_to_duplicate_modifier_group: 'Modifizierergruppe konnte nicht dupliziert werden',
    modifier_group_duplicated_successfully: 'Modifizierergruppe erfolgreich dupliziert',
    failed_to_duplicate_modifier: 'Modifizierer konnte nicht dupliziert werden',
    modifier_duplicated_successfully: 'Modifizierer erfolgreich dupliziert',
  },
};
