export default {
  fields: {
    discount: {
      label: 'Discount',
    },
    usage_threshold: {
      label: 'Minimum order amount',
    },
  },
};
