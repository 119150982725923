export default {
  title: 'Add table numbers',
  fields: {
    select_all: 'Select all',
    table_number: 'Table number',
    edit: 'Edit',
  },
  buttons: {
    edit: 'Edit',
    create_tables: 'Create tables',
    delete_tables: 'Delete tables',
    download_qr_codes: 'Download QR Codes',
  },
  modals: {
    delete_tables: {
      title: 'Delete selected tables',
      description: 'All selected table numbers & names will be deleted.',
      buttons: {
        yes: 'Yes',
        no: 'No',
      },
    },
  },
  toasts: {
    table_creation_disabled: 'Table creation is disabled because restaurant has no subdomain set up',
    failed_to_load_data: 'Failed to load tables data',
    failed_to_download_qr_codes: 'Failed to download QR codes',
  },
};
