export default {
  title: 'Prüfen Sie die Wartbarkeit der Adresse',
  or: 'oder',
  form: {
    company: {
      label: 'Restaurant auswählen',
    },
    address: {
      label: 'Geben Sie die Adresse ein',
    },
  },
  result: {
    positive: '{{address}} ist für die externe Lieferung geeignet',
    negative: '{{address}} ist nicht für die externe Lieferung geeignet',
  },
  buttons: {
    check: 'Siehe',
  },
  toasts: {
    error: 'Überprüfung der Dienstbarkeit der Adresse fehlgeschlagen',
  },
};
