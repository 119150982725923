import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { collect } from 'collect.js';
import { CLabel } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { PROMO_CODE_DISCOUNT_TYPES } from '../../services/exports/Constants';
import useHelpers from '../../hooks/useHelpers';
import CurrencyInput from '../form/CurrencyInput';
import PercentageInput from '../form/PercentageInput';

export default function PromoCodeComponent({
  discountType,
  data,
  update,
  remove,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:PromoCode:PromoCodeComponent' });

  const renderDiscount = () => {
    if (discountType === PROMO_CODE_DISCOUNT_TYPES.percentage) {
      return (
        <PercentageInput
          value={data.discount}
          onChange={(value) => update('discount', value)}
        />
      );
    }

    return (
      <CurrencyInput
        value={data.discount}
        onChange={(value) => update('discount', value)}
      />
    );
  };

  return (
    <div className="tw-relative border-t">
      <div
        className="tw-h-6 tw-w-6 tw-flex tw-justify-center tw-absolute tw-top-0 tw-right-2 -tw-translate-y-1/2 bg-danger tw-rounded-md cursor-pointer"
        onClick={remove}
      >
        <CIcon
          name="cil-trash"
          className="my-auto text-white"
          color="white"
        />
      </div>
      <div className="tw-p-small">
        <div className="tw-flex">
          <CLabel className="my-auto tw-w-full">{t('fields.discount.label')}</CLabel>
          {renderDiscount()}
        </div>
        <div className="tw-mt-medium tw-flex">
          <CLabel className="my-auto tw-w-full">{t('fields.usage_threshold.label')}</CLabel>
          <CurrencyInput
            value={data.usage_threshold}
            onChange={(value) => update('usage_threshold', value)}
          />
        </div>
      </div>
    </div>
  );
}
