import React from 'react';
import DineInOrderDetailsModal from './DineInOrderDetailsModal';
import TakeoutOrderDetailsModal from './TakeoutOrderDetailsModal';
import { ORDER_METHODS } from '../../services/exports/Constants';

export default function OrderDetailsModal({
  show = false,
  toggleModal,
  data,
}) {
  return data?.method === ORDER_METHODS.dine_in
    ? (
      <DineInOrderDetailsModal
        show={show}
        toggleModal={toggleModal}
        data={data}
      />
    ) : (
      <TakeoutOrderDetailsModal
        show={show}
        toggleModal={toggleModal}
        data={data}
      />
    );
}
