export default {
  title: 'Unbezahlte Bestellungen',
  empty_list: 'Keine unbezahlten Bestellungen',
  fields: {
    company: 'Restaurant',
    date: 'Datum',
    time: 'Uhrzeit',
    unpaid_amount: 'Übriggebliebene zu zahlen',
  },
  labels: {
    total_orders: 'Bestellungen insgesamt',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
