import { ORDER_METHODS } from '../../../../../services/exports/Constants';

export default {
  empty_list: 'No products yet.',
  variants: 'Variants',
  fields: {
    number: '#',
    image: 'Image',
    name: 'Name',
    price: 'Price',
    prices: 'Prices',
    free_eligible: 'Free Eligible',
    promotable: 'Promotable',
    description: 'Description',
    available: 'Available',
  },
  labels: {
    visible: 'Visible in menu',
    hidden: 'All hidden',
  },
  order_method_prices: {
    base: 'Base',
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ORDER_METHODS.room_service]: 'Room/Table service',
    [ORDER_METHODS.dine_in]: 'Dine in',
  },
  buttons: {
    delete_products_one: 'Delete product',
    delete_products_other: 'Delete products',
    add_product: 'Add product',
    show_products: 'Show products',
    hide_products: 'Hide products',
    add: 'Add',
    edit: 'Edit',
    duplicate: 'Duplicate',
    delete: 'Delete',
    delete_all: 'Delete all',
  },
  modals: {
    delete_variants: {
      title: 'Are you sure?',
      description: 'Following variants will be deleted: {{items}}',
    },
  },
  toasts: {
    failed_to_duplicate_category: 'Failed to duplicate category',
    category_duplicated_successfully: 'Category duplicated successfully',
    failed_to_duplicate_product: 'Failed to duplicate product',
    product_duplicated_successfully: 'Product duplicated successfully',
    failed_to_delete_variant: 'Failed to delete variant',
    variant_got_deleted: 'Variant got deleted',
    something_went_wrong: 'Something went wrong',
  },
};
