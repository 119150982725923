export default {
  title: 'Tabellen erstellen',
  labels: {
    table_number: 'Tabellennummer',
  },
  buttons: {
    create: 'Erstellen',
    add_rows: '+ Zeilen hinzufügen',
  },
  toasts: {
    success: 'Die Erstellung der Tabellen kann einige Zeit dauern, bitte lade die Seite in ein paar Sekunden neu',
  },
};
