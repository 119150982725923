import React from 'react';
import ChevronLeftIcon from '../../resources/assets/icons/ChevronLeftIcon';

export default function BackButton({
  onClick,
}) {
  return (
    <button type="button" className="outline-none border-none bg-none d-flex justify-content-center justify-items-center" onClick={onClick}>
      <div className="w-12 h-12 bg-background-inkWhite-white_1 shadow-icon rounded-full d-flex justify-content-center">
        <ChevronLeftIcon name="cil-c" className="my-auto" />
      </div>
    </button>
  );
}
