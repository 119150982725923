export default {
  title: 'Promo code',
  fields: {
    preset: {
      label: 'Preset',
    },
    presets: {
      master: 'Master',
      campaign: 'Campaign',
      one_time: 'One time',
    },
    code: {
      label: 'Code',
      placeholder: 'Enter code',
    },
    discount_type: {
      label: 'Discount type',
    },
    quantity: {
      label: 'Quantity',
      placeholder: 'Infinite',
    },
    expires_at: {
      label: 'Expiration date',
      placeholder: 'Enter date or leave blank',
    },
    discount: {
      label: 'Discount',
    },
    usage_threshold: {
      label: 'Usage threshold',
    },
  },
  messages: {
    master_warning: 'Creating new "master" promo code will expire current master promo code',
  },
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
  },
  toasts: {
    promo_code_got_created: 'Promo code got created',
    promo_code_got_updated: 'Promo code got updated',
  },
};
