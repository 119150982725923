export default {
  tabs: {
    tables: 'Tables',
    order_details: 'Order info',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save the changes',
    changes_got_saved: 'Changes got saved',
  },
};
