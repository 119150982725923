import React, { useState } from 'react';
import {
  CCol,
  CInputRadio,
  CLabel,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import CustomButton from '../CustomButton';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useApiClient from '../../hooks/useApiClient';
import { PROMO_CODE_DISCOUNT_TYPES, PROMO_CODE_TAGS } from '../../services/exports/Constants';
import PromoCodeConstructor from '../promo-code/PromoCodeConstructor';
import useHelpers from '../../hooks/useHelpers';

export default function PromoCodeStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:PromoCodeStep' });

  const {
    isSkippable,
    moveBackwards,
    moveForward,
    skip,
    skipping,
  } = props;

  const { company } = useSelector((store) => store.currentCompany);

  const { PromoCodesManager } = useApiClient();
  const { formatCurrency } = useHelpers();

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [options, setOptions] = useState([
    {
      tag: PROMO_CODE_TAGS.master,
      discount_type: PROMO_CODE_DISCOUNT_TYPES.fixed_amount,
      code: company.name.replace(/[^A-Za-z0-9]/, '').slice(0, 8).toUpperCase(),
      quantity: null,
      components: [
        { discount: 4, usage_threshold: 20 },
        { discount: 3, usage_threshold: 20 },
        { discount: 3, usage_threshold: 20 },
      ],
    },
    {
      tag: PROMO_CODE_TAGS.master,
      code: company.name.replace(/[^A-Za-z0-9]/, '').slice(0, 8).toUpperCase(),
      discount_type: PROMO_CODE_DISCOUNT_TYPES.fixed_amount,
      quantity: null,
      components: [
        { discount: 3, usage_threshold: 16 },
        { discount: 3, usage_threshold: 16 },
        { discount: 2, usage_threshold: 16 },
      ],
    },
    {
      tag: PROMO_CODE_TAGS.master,
      code: company.name.replace(/[^A-Za-z0-9]/, '').slice(0, 8).toUpperCase(),
      discount_type: PROMO_CODE_DISCOUNT_TYPES.fixed_amount,
      quantity: null,
      components: [
        { discount: 2, usage_threshold: 10 },
        { discount: 2, usage_threshold: 10 },
        { discount: 2, usage_threshold: 10 },
      ],
    },
  ]);

  const updateOption = (index, field, value) => setOptions(
    (current) => collect(current).put(index, {
      ...current[index],
      [field]: value,
    }).toArray(),
  );

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    if (selectedOptionIndex === null) {
      return skip();
    }

    setLoading(true);
    const { data: responseData, success } = await PromoCodesManager.create(options[selectedOptionIndex]);
    setLoading(false);

    if (!success) {
      return setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });
    }

    return skip();
  }

  function renderStepperButtons() {
    return (
      <div className="mb-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <CustomButton
              title={t('buttons.back')}
              onClick={moveBackwards}
              className="mr-2 mb-1"
              buttonType="third"
            />
            <CustomButton
              title={t('buttons.next')}
              onClick={save}
              loading={loading}
            />
          </div>
          {isSkippable && (
            <CustomButton
              title={t('buttons.skip')}
              buttonType="secondary"
              onClick={skip}
              loading={skipping}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <CCol className="mt-4">
      {renderFeedbackLabel}
      <div>
        {options.map((item, index) => (
          <>
            <div className="d-flex position-relative mb-3">
              <CInputRadio
                id={`promo-code-option-${index}`}
                checked={index === selectedOptionIndex}
                onClick={() => setSelectedOptionIndex(index)}
                className="ml-0 position-absolute top-1/2 -translate-y-1/2 cursor-pointer"
              />
              <CLabel
                htmlFor={`promo-code-option-${index}`}
                className="ml-4 my-auto cursor-pointer"
              >
                {formatCurrency(collect(item.components).sum('discount'))}
              </CLabel>
            </div>
            {index === selectedOptionIndex && (
              <PromoCodeConstructor
                discountType={item.discount_type}
                components={item.components}
                setComponents={(value) => updateOption(index, 'components', value)}
              />
            )}
          </>
        ))}
        <div className="d-flex position-relative mb-3">
          <CInputRadio
            id="promo-code-option-none"
            checked={selectedOptionIndex === null}
            onClick={() => setSelectedOptionIndex(null)}
            className="ml-0 position-absolute top-1/2 -translate-y-1/2 cursor-pointer"
          />
          <CLabel
            htmlFor="promo-code-option-none"
            className="ml-4 my-auto cursor-pointer"
          >
            {t('options.none')}
          </CLabel>
        </div>
      </div>
      {renderStepperButtons()}
    </CCol>
  );
}
