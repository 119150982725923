export default {
  title: 'Tabelle aktualisieren',
  labels: {
    table_number: 'Tabellennummer',
  },
  buttons: {
    update: 'Aktualisieren',
  },
  toasts: {
    success: 'Die Tabelle wurde erfolgreich aktualisiert',
  },
};
