import { DeliveryStatus } from '../../../../../../services/exports/Constants';

export default {
  title: 'Externe Lieferungen',
  fields: {
    pickup: 'Abholung',
    dropoff: 'Abwurf',
    status: 'Status',
    estimated_time: 'Geschätzte Zeit',
    actual_time: 'Tatsächliche Zeit',
    actions: 'Aktionen',
    time: 'Zeit',
    pickup_estimate: 'Schätzung der Abholung',
    dropoff_estimate: 'Kostenvoranschlag für die Übergabe',
  },
  labels: {
    pickup: 'Abholung',
    dropoff: 'Abwurf',
  },
  delivery_statuses: {
    [DeliveryStatus.PendingAssign]: 'Ausstehender Fahrer',
    [DeliveryStatus.PendingMerchant]: 'Ausstehender Fahrer',
    [DeliveryStatus.Scheduled]: 'Ausstehender Fahrer',
    [DeliveryStatus.RunnerAssigned]: 'Zugewiesener Fahrer',
    [DeliveryStatus.EnRoutePickup]: 'Auf dem Weg zur Abholung',
    [DeliveryStatus.ArrivedPickup]: 'Angekommen bei der Abholung',
    [DeliveryStatus.PickedUp]: 'Abgeholt',
    [DeliveryStatus.EnRouteDropoff]: 'Auf dem Weg zur Übergabe',
    [DeliveryStatus.ArrivedDropoff]: 'Angekommen bei der Übergabe',
    [DeliveryStatus.Completed]: 'Abgeschlossen',
    [DeliveryStatus.Cancelled]: 'Abgesagt',
  },
  buttons: {
    actions: 'Aktionen',
    open_tracking_url: 'Lieferung verfolgen',
    show_order_details: 'Auftragsdetails anzeigen',
    call_restaurant: 'Restaurant anrufen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
