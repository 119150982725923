export default {
  table: 'Tisch',
  customer_one: 'Einzelperson',
  customer_other: '{{count}} Personen dieser Gruppe',
  left_to_pay: 'übrig zu zahlen',
  total_order: 'Gesamt',
  amount_refunded: '{{amount}} rückerstattet',
  sections: {
    payments: {
      title: 'Bezahlt',
      fields: {
        time: 'Zeit',
        tip: 'Trinkgeld',
        total: 'Gesamt',
      },
    },
    orders: {
      title: 'Bestellungen',
    },
    cancelled_orders: {
      title: 'Stornierte Bestellungen',
      labels: {
        cancelled: 'Storniert',
      },
    },
  },
};
