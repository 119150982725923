export default {
  title: 'Unpaid orders',
  empty_list: 'No unpaid orders',
  fields: {
    company: 'Restaurant',
    date: 'Date',
    time: 'Time',
    unpaid_amount: 'Left to pay',
  },
  labels: {
    total_orders: 'Total orders',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
