import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {
  Button, Switch, Table,
  Flex, Form, Input, Popconfirm, Select, Space, Spin,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { collect } from 'collect.js';
import CardWrapper from '../../components/cards/CardWrapper';
import LoadingScreen from '../../components/loading/LoadingScreen';
import useForm from '../../hooks/useForm';
import useApiClient from '../../hooks/useApiClient';
import GooglePlaceHelpers from '../../services/helpers/GooglePlaceHelpers';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import { MENU_PROVIDERS, MERCHANT_BUSINESS_TYPE, SUPPORTED_COUNTRIES } from '../../services/exports/Constants';
import PhoneNumberInput from '../../components/form/PhoneNumberInput';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import ImportMenu from '../../components/menu/ImportMenu';
import GooglePlacesSearch from '../../components/form/GooglePlacesSearch';
import useHelpers from '../../hooks/useHelpers';

export const mode_demo = 'demo';
export const mode_merchant = 'merchant';

export default function CompaniesView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Companies' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { profile } = useSelector((state) => state.initialData);

  const { CompaniesManager, MerchantsManager } = useApiClient();
  const { feedback, setFeedback, renderFeedbackLabel } = useFeedbackLabel();
  const { formatDateTime } = useHelpers();

  const [mode, setMode] = useState(import.meta.env.PROD ? mode_merchant : mode_demo);
  const { data, update } = useForm({
    name: null,
    google_place: null,
    business_type: MERCHANT_BUSINESS_TYPE.INDIVIDUAL,
    business_country: profile?.business_country ?? '',
    business_state: profile?.business_state ?? '',
    business_city: profile?.business_city ?? '',
    business_address: null,
    business_zip_code: null,
    phone_number: null,
    email: null,
    password: null,

    sync_lieferando_brand: false,
    lieferando_slug: null,

    import_menu: false,
    menu_provider: null,
    menu_provider_id: null,
  });

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(null);

  useEffect(
    () => loadCompanies(),
    [serviceProvider?.id],
  );

  useEffect(() => {
    data.menu_provider === MENU_PROVIDERS.lieferando
      && data.sync_lieferando_brand
      && !!data.lieferando_slug
      && data.lieferando_slug !== ''
      && update('menu_provider_id', data.lieferando_slug);
  }, [
    data.sync_lieferando_brand,
    data.lieferando_slug,
    data.menu_provider,
  ]);

  const createMerchant = async () => {
    setCreating(true);
    const { success, data: responseData } = await MerchantsManager.create({
      ...data,
      company_name: data.name,
      password_confirmation: data.password,
      google_places_id: data.google_place?.place_id ?? null,
      menu_provider_id: data.menu_provider === MENU_PROVIDERS.foodamigos ? `${data.menu_provider_id?.id}` : data.menu_provider_id,
    });
    setCreating(false);

    if (!success) {
      toast.error(t('toasts.failed_to_create_company'));

      return setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });
    }

    toast.success(t('toasts.company_created'));

    ReduxHooks.dispatch(
      actionCreators.currentCompany.set(responseData),
    );

    setCompanies((current) => [
      responseData,
      ...current,
    ]);
  };

  const createDemo = async () => {
    setCreating(true);
    const { success, data: responseData } = await CompaniesManager.createDemo({
      ...data,
      google_places_id: data.google_place?.place_id ?? null,
      business_country: GooglePlaceHelpers.getCountry(data.google_place),
      business_state: GooglePlaceHelpers.getState(data.google_place),
      business_city: GooglePlaceHelpers.getCity(data.google_place),
      business_address: GooglePlaceHelpers.getAddress(data.google_place),
      business_zip_code: GooglePlaceHelpers.getZipCode(data.google_place),
      menu_provider_id: data.menu_provider === MENU_PROVIDERS.foodamigos ? `${data.menu_provider_id?.id}` : data.menu_provider_id,
    });
    setCreating(false);

    if (!success) {
      toast.error(t('toasts.failed_to_create_company'));

      return setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });
    }

    toast.success(t('toasts.company_created'));

    ReduxHooks.dispatch(
      actionCreators.currentCompany.set(responseData),
    );
  };

  const loadCompanies = async (page) => {
    setLoading(true);
    const { success, data: responseData } = await CompaniesManager.get({
      page,
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_recently_created_companies'));
    }

    setCompanies(responseData);
  };

  const deleteCompany = async (id) => {
    if (import.meta.env.PROD) {
      return;
    }

    setDeleting(id);
    const { success } = await CompaniesManager.delete(id);
    setDeleting(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_delete_demo'));
    }

    setCompanies((current) => ({
      ...current,
      data: collect(current.data).where('id', '!=', id).toArray(),
    }));

    return toast.success(t('toasts.demo_got_deleted'));
  };

  const onPlaceSelected = (place) => {
    const name = place.name.split(',')[0] ?? place.name;

    update('name', name);
    update('google_place', place);
    update('business_country', GooglePlaceHelpers.getCountry(place));
    update('business_state', GooglePlaceHelpers.getState(place));
    update('business_city', GooglePlaceHelpers.getCity(place));
    update('business_address', GooglePlaceHelpers.getAddress(place));
    update('business_zip_code', GooglePlaceHelpers.getZipCode(place));
  };

  const providers = useMemo(() => [
    {
      is_supported: serviceProvider.regional_config.supported_menu_providers.includes(MENU_PROVIDERS.lieferando),
      renderSettings: () => (data.sync_lieferando_brand || data.sync_lieferando_menu) && (
        <div className="tw-mt-small">
          <Form.Item label={t('form.lieferando_slug.label')}>
            <Input
              value={data.lieferando_slug ?? ''}
              onChange={(e) => update('lieferando_slug', e.target.value)}
              placeholder={t('form.lieferando_slug.placeholder')}
            />
          </Form.Item>
        </div>
      ),
      renderToggles: () => (
        <Flex>
          <Switch
            checked={data.sync_lieferando_brand}
            onChange={(checked) => update('sync_lieferando_brand', checked)}
            id="sync-lieferando-brand"
          />
          <label
            htmlFor="sync-lieferando-brand"
            className="tw-ml-mini tw-my-auto"
          >
            {t('form.sync_lieferando_brand.label')}
          </label>
        </Flex>
      ),
    },
    {
      id: MENU_PROVIDERS.wolt,
      is_supported: true,
      renderSettings: () => data.import_menu && (
        <ImportMenu
          data={{
            provider: data.menu_provider,
            provider_id: data.menu_provider_id,
          }}
          update={(field, value) => update(`menu_${field}`, value)}
          nullable
          className="tw-mt-small"
        />
      ),
      renderToggles: () => (
        <Flex className="tw-mt-medium">
          <Switch
            checked={data.import_menu}
            onChange={(checked) => update('import_menu', checked)}
            id="import-menu"
          />
          <label
            htmlFor="import-menu"
            className="tw-ml-mini tw-my-auto"
          >
            {t('form.import_menu.label')}
          </label>
        </Flex>
      ),
    },
  ], [data, serviceProvider, feedback]);

  const renderContent = () => (
    <div>
      <h2>{t('title')}</h2>
      <CardWrapper
        containerClassName="tw-mt-small"
        header={(
          <div className="tw-flex tw-items-center tw-justify-between">
            <h6>{t(`sections.new_company.${mode}`)}</h6>
            {!import.meta.env.PROD && (
              <Flex>
                <Switch
                  checked={mode === mode_demo}
                  onChange={(checked) => setMode(checked ? mode_demo : mode_merchant)}
                  id="is-demo"
                />
                <label
                  htmlFor="is-demo"
                  className="tw-ml-mini tw-my-auto"
                >
                  {mode === mode_demo ? t('labels.create_demo') : t('labels.create_company')}
                </label>
              </Flex>
            )}
          </div>
        )}
        footer={(
          <Button
            type="primary"
            onClick={() => (mode === mode_demo ? createDemo() : createMerchant())}
            loading={creating}
          >
            {t('buttons.create')}
          </Button>
        )}
      >
        <Form layout="vertical" className="tw-mt-none">
          {renderFeedbackLabel}
          <Form.Item label={t('form.name.label')} required>
            <GooglePlacesSearch
              value={data.name ?? ''}
              onPlaceSelected={onPlaceSelected}
              onChange={(e) => update('name', e.target.value)}
              placeholder={t('form.name.placeholder')}
              options={{
                fields: ['name', 'place_id', 'address_components'],
                types: ['bakery', 'bar', 'cafe', 'restaurant', 'food'],
              }}
            />
            {data.google_place && (
              <Space className="tw-mt-small">
                <a href="#">
                  {t('form.google_place.label', { name: data.google_place.name })}
                </a>
                <DeleteOutlined
                  className="tw-my-auto cursor-pointer"
                  onClick={() => update('google_place', null)}
                />
              </Space>
            )}
          </Form.Item>
          {mode !== mode_demo && (
            <>
              <Form.Item label={t('form.business_type.label')} required>
                <Select
                  value={data.business_type}
                  onChange={(value) => update('business_type', value)}
                  options={[MERCHANT_BUSINESS_TYPE.COMPANY, MERCHANT_BUSINESS_TYPE.INDIVIDUAL].map((item) => ({
                    label: t(`form.business_type.options.${item}`),
                    value: item,
                  }))}
                />
              </Form.Item>
              <Form.Item label={t('form.business_country.label')} required>
                <CountryDropdown
                  value={data.business_country}
                  whitelist={SUPPORTED_COUNTRIES}
                  valueType="short"
                  onChange={(value) => update('business_country', value)}
                  classes="form-control"
                  defaultOptionLabel={t('form.business_country.placeholder')}
                />
              </Form.Item>
              <Form.Item label={t('form.business_state.label')} required>
                <RegionDropdown
                  country={data.business_country}
                  value={data.business_state}
                  countryValueType="short"
                  onChange={(value) => update('business_state', value)}
                  classes="form-control"
                  defaultOptionLabel={t('form.business_state.placeholder')}
                />
              </Form.Item>
              <Form.Item label={t('form.business_city.label')} required>
                <Input
                  value={data.business_city ?? ''}
                  onChange={(e) => update('business_city', e.target.value)}
                  placeholder={t('form.business_city.placeholder')}
                />
              </Form.Item>
              <Form.Item label={t('form.business_address.label')} required>
                <Input
                  value={data.business_address ?? ''}
                  onChange={(e) => update('business_address', e.target.value)}
                  placeholder={t('form.business_address.placeholder')}
                />
              </Form.Item>
              <Form.Item label={t('form.business_zip_code.label')} required>
                <Input
                  value={data.business_zip_code ?? ''}
                  onChange={(e) => update('business_zip_code', e.target.value)}
                  placeholder={t('form.business_zip_code.placeholder')}
                />
              </Form.Item>
              <Form.Item label={t('form.phone_number.label')} required>
                <PhoneNumberInput
                  defaultCountry={serviceProvider.country}
                  value={data.phone_number ?? ''}
                  onChange={(value) => update('phone_number', value)}
                  international
                  className="w-full font-sm relative"
                  countryCallingCodeEditable={false}
                />
              </Form.Item>
              <Form.Item label={t('form.email.label')} required>
                <Input
                  value={data.email}
                  autocomplete={null}
                  onChange={(e) => update('email', e.target.value)}
                  placeholder={t('form.email.placeholder')}
                  type="email"
                />
              </Form.Item>
              <Form.Item label={t('form.password.label')} required>
                <Input.Password
                  value={data.password}
                  autocomplete="new-password"
                  onChange={(e) => update('password', e.target.value)}
                  placeholder={t('form.password.placeholder')}
                />
              </Form.Item>
            </>
          )}
          {providers.map((item) => item.is_supported && (
            <React.Fragment key={`menu-provider-${item.id}`}>
              {item.renderToggles()}
              {item.renderSettings()}
            </React.Fragment>
          ))}
        </Form>
      </CardWrapper>
      <div className="tw-mt-large">
        <h2>{t('sections.recently_created')}</h2>
        <div className="tw-mt-medium tw-bg-background-inkWhite-white_0 tw-shadow tw-rounded-lg">
          <Table
            dataSource={companies.data}
            columns={[
              {
                key: 'name',
                title: t('fields.name'),
                dataIndex: 'name',
              },
              {
                key: 'created_at',
                title: t('fields.created_at'),
                render: (_, item) => formatDateTime(item.created_at),
              },
              {
                key: 'actions',
                title: t('fields.actions'),
                render: (_, item) => (
                  <Space size="middle">
                    {!import.meta.env.PROD && (
                      <Popconfirm
                        title={t('popups.delete_demo.title')}
                        icon={<DeleteOutlined />}
                        onConfirm={() => deleteCompany(item.id)}
                        okButtonProps={{
                          danger: true,
                          loading: deleting === item.id,
                        }}
                      >
                        <a className="!tw-text-[#1677ff]">{deleting === item.id ? <Spin className="tw-mx-auto" /> : t('buttons.delete')}</a>
                      </Popconfirm>
                    )}
                  </Space>
                ),
              },
            ]}
            pagination={{
              current: companies.current_page,
              total: companies.total,
              pageSize: companies.per_page,
              onChange: (page) => loadCompanies(page),
            }}
            loading={loading}
            className="rounded-none table-responsive position-initial"
            rowKey={(item) => item.id}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && renderContent()}
    </>
  );
}
