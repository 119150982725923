import Query from '../../query-builder';
import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';
import ApiResourceManager from '../ApiResourceManager';

export default class CompaniesManager extends ApiResourceManager {
  createDemo = (data) => this.request({
    method: 'POST',
    url: '/demos',
    data,
  });

  getAll = (limit = null) => this.request({
    method: 'GET',
    url: new Query()
      .for('all-companies')
      .limit(limit)
      .get(),
  });

  getSetupStatuses = (page, filters = {}) => this.request({
    method: 'GET',
    url: new Query()
      .for('companies-setup-status')
      .where('name', filters.name)
      .where('has_live_orders', filters.has_live_orders)
      .where('setup_package_fulfilled', filters.setup_package_fulfilled)
      .where('flyers_ordered', filters.flyers_ordered)
      .page(page)
      .get(),
  });

  getWithUnfulfilledSetupPackage = (limit = null) => this.request({
    method: 'GET',
    url: new Query()
      .for('all-companies')
      .where('with_unfulfilled_setup_package', true)
      .limit(limit)
      .get(),
  });

  get = (data = {}, sort = '-companies.id') => this.request({
    method: 'GET',
    url: new Query()
      .for('companies')
      .where('companies.name', data.name ?? null)
      .whereIn('except', data.except ?? [])
      .whereIn('merchant.sso_ids', data.merchant?.sso_ids ?? [])
      .when(
        data.id,
        (builder) => builder.where('id', data.id),
      )
      .page(data.page ?? 1)
      .sort(sort)
      .get(),
  });

  show = async (id) => {
    const response = await this.request({
      method: 'GET',
      url: `/companies/${id}`,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  update = async (id, data) => this.request({
    method: 'PUT',
    url: `/companies/${id}`,
    data,
  });

  delete = async (id) => this.request({
    method: 'DELETE',
    url: `/companies/${id}`,
  });

  updateAndSetCurrentCompany = async (id, data) => {
    const response = await this.update(id, data);

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateSettings = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/settings',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateMenuSettings = async (data) => this.request({
    method: 'PUT',
    url: '/menu-settings',
    data,
  });

  updateTakeoutSettings = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/takeout-settings',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateDineInSettings = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/dine-in-settings',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateTipSettings = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/tip-settings',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateReceiptSettings = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/receipt-settings',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateWorkSchedule = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/work-schedule',
      data: {
        opening_hours: data,
      },
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateDeliverySchedule = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/delivery-schedule',
      data: {
        has_delivery_schedule: data.length > 0,
        delivery_schedule: data,
      },
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateHolidays = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/holidays',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  toggleDeliverySchedule = async (hasDeliverySchedule) => {
    const response = await this.request({
      method: 'PUT',
      url: '/delivery-schedule',
      data: {
        has_delivery_schedule: hasDeliverySchedule,
      },
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateLocation = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/location',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateCustomRewardInfo = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/custom-reward-info',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  updateGoogleProfile = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/google-profile',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  getIntegrationsSettings = async () => this.request({
    method: 'GET',
    url: 'settings/integrations',
  });

  createWinOrderIntegration = async (data) => this.request({
    method: 'POST',
    url: 'settings/integrations/win_order',
    data,
  });

  saveIntegration = async (data) => this.request({
    method: 'PUT',
    url: 'settings/integrations',
    data,
  });

  syncCompany = (id) => this.request({
    method: 'POST',
    url: `/reservations/companies/${id}/sync`,
  });

  syncReservationScheduleExceptions = (id) => this.request({
    method: 'POST',
    url: `/reservations/companies/${id}/sync-schedule-exceptions`,
  });
}
