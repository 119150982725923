export default {
  title: 'Update table',
  labels: {
    table_number: 'Table number',
  },
  buttons: {
    update: 'Update',
  },
  toasts: {
    success: 'Successfully updated the table',
  },
};
