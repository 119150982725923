import ApiResourceManager from '../ApiResourceManager';

export default class TablesManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/tables',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/tables/batch',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/tables/${id}`,
    data,
  });

  delete = (ids) => this.request({
    method: 'DELETE',
    url: '/tables',
    data: {
      ids,
    },
  });

  reorder = (ids) => this.request({
    method: 'PUT',
    url: '/tables-order',
    data: {
      ids,
    },
  });
}
