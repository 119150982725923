import { PAYMENT_METHODS } from '../../../../../services/exports/Constants';

export default {
  sections: {
    recent_orders: 'Letzte Bestellungen',
  },
  fields: {
    phone_number: 'Telefon',
    email: 'Email',
    lifetime_spent: 'Lebenslang € ausgegeben',
    lifetime_points: 'Lebenslang Punkte',
    total_orders: 'Bestellungen Gesamt',
    created_at: 'Mitglied seit',
  },
  payment_methods: {
    [PAYMENT_METHODS.CARD]: 'Bezahlt Kreditkarte',
    [PAYMENT_METHODS.PAYPAL]: 'Bezahlt PayPal',
    [PAYMENT_METHODS.APPLE_PAY]: 'Bezahlt Apple Pay',
    [PAYMENT_METHODS.GOOGLE_PAY]: 'Bezahlt Google Pay',
    [PAYMENT_METHODS.SOFORT]: 'Bezahlt Sofort',
    [PAYMENT_METHODS.GIROPAY]: 'Bezahlt Giropay',
    [PAYMENT_METHODS.SEPA_DEBIT]: 'Bezahlt Bank debit',
    [PAYMENT_METHODS.CASH]: 'Bezahlt Bargeld',
    [PAYMENT_METHODS.BACS_DEBIT]: 'Bezahlt Bank debit',
  },
  labels: {
    order_id: 'Bestell id',
    items_one: '{{count}} Artikel',
    items_other: '{{count}} Artikel',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
