export default {
  sections: {
    dine_in_orders: {
      title: 'Dine in orders',
      fields: {
        dine_in_enabled: {
          label: 'Dine in live',
        },
        last_order_gap_dine_in: {
          label: 'Last possible <strong>dine in</strong> order (minutes before closing)',
          option: '{{value}} min',
        },
      },
    },
  },
  buttons: {
    save: 'Save',
  },
};
