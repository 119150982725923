export default {
  title: 'Gallerie',
  sections: {
    logo: 'Logo',
    web_images: 'Galeriebilder online bestellen',
    dine_in_images: 'Dine in App Restaurant Bilder ',
  },
  toasts: {
    image_uploaded: 'Die Änderungen wurden erfolgreich gespeichert.',
    image_upload_failed: 'Speichern der Änderungen fehlgeschlagen',
  },
};
