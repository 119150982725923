export default {
  title: 'All Customers',
  fields: {
    name: 'Name',
    phone: 'Phone',
    recent_order: 'Recent Order',
    total_orders: 'Total Orders',
    current_points: 'Current Points',
    google_review: 'Google Review',
  },
  empty_list: 'No customers yet',
  yes: 'Yes',
  no: 'No',
  buttons: {
    export: 'Export',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
