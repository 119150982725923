import {
  ORDER_METHODS,
  SMART_PRICING_MODIFIER_TYPE,
  SMART_PRICING_RESOURCE_TYPE,
} from '../../../../../services/exports/Constants';

export default {
  title: {
    [SMART_PRICING_RESOURCE_TYPE.company]: '{{name}} global smart pricing rule',
    [SMART_PRICING_RESOURCE_TYPE.menu]: '{{name}} menu smart pricing rule',
    [SMART_PRICING_RESOURCE_TYPE.category]: '{{name}} category smart pricing rule',
    [SMART_PRICING_RESOURCE_TYPE.product]: '{{name}} product smart pricing rule',
    [SMART_PRICING_RESOURCE_TYPE.modifier_group]: '{{name}} modifier group smart pricing rule',
    [SMART_PRICING_RESOURCE_TYPE.modifier]: '{{name}} modifier smart pricing rule',
  },
  labels: {
    new: 'New',
    base_price: '{{name}} base price: {{value}}',
  },
  form: {
    resource: {
      [SMART_PRICING_RESOURCE_TYPE.menu]: 'Menu',
      [SMART_PRICING_RESOURCE_TYPE.category]: 'Category',
      [SMART_PRICING_RESOURCE_TYPE.product]: 'Product',
      [SMART_PRICING_RESOURCE_TYPE.modifier_group]: 'Modifier group',
      [SMART_PRICING_RESOURCE_TYPE.modifier]: 'Modifier',
      placeholder: 'Select {{resource}}',
    },
    order_methods: {
      label: 'Order methods',
      placeholder: 'Select order methods on which the rule is applied',
      options: {
        [ORDER_METHODS.pickup]: 'Pickup',
        [ORDER_METHODS.delivery]: 'Delivery',
        [ORDER_METHODS.room_service]: 'Room/Table service',
        [ORDER_METHODS.dine_in]: 'Dine in',
      },
    },
    modifier_type: {
      label: 'Price modifier type',
      placeholder: 'Select price modifier type',
      tooltip: {
        title: 'Modifier type defines the way prices are modified',
        description: {
          [SMART_PRICING_MODIFIER_TYPE.absolute]: 'Absolute: overwrites the resource price to the specified modifier value',
          [SMART_PRICING_MODIFIER_TYPE.percentage]: 'Percentage: adds/subtracts the specified modifier percentage value to/from the resource base price depending on modifier value sign',
          [SMART_PRICING_MODIFIER_TYPE.fixed_value]: 'Fixed value: adds/subtracts the specified modifier fixed value to/from the base price depending on modifier value sign',
        },
      },
      options: {
        [SMART_PRICING_MODIFIER_TYPE.absolute]: 'Absolute',
        [SMART_PRICING_MODIFIER_TYPE.percentage]: 'Percentage',
        [SMART_PRICING_MODIFIER_TYPE.fixed_value]: 'Fixed value',
      },
    },
    modifier_value: {
      label: 'Price modifier value',
    },
    round: {
      label: 'Round the result price',
      tooltip: 'Rounds the result price up by 10 (e.g. 14.91, 14.93, 14.99 become 15.00). Mostly useful for percentage rules',
    },
    is_visible: {
      label: 'Show customer as a deal',
    },
    name: {
      label: 'Name',
      placeholder: 'Enter customer-friendly name',
      default: '{{value}} off on {{resource}}',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
