export default {
  title: 'Create tables',
  labels: {
    table_number: 'Table number',
  },
  buttons: {
    create: 'Create',
    add_rows: '+ Add rows',
  },
  toasts: {
    success: 'Tables creation takes some time, please refresh the page in a few seconds',
  },
};
