export default {
  title: 'Metriken Dashboard',
  metrics: {
    sales: 'Umsatz',
    online_sales: 'Online-Verkäufe',
    cash_sales: 'Barverkäufe',
    total_sales: 'Gesamtumsatz',
    payouts: 'Auszahlungen',
    last_payout_amount: 'Letzte Auszahlung',
    pending_payout_amount: 'Im Transit',
    future_payout_amount: 'Verfügbar zukünftige Auszahlungen',
    tips: 'Trinkgeld',
    reservations: 'Reservierungen',
    total_reservations: 'Gesamt',
    website_reservations: 'Website',
    google_reservations: 'Google',
    savings: 'Ersparnisse Lieferando Gebühren',
    rejected_orders: 'Stornierungen',
    rejected_orders_amount: 'Umsatz',
    rejected_orders_count: 'Anzahl',
    rejected_orders_rate: '% Total orders',
    customers: 'Kunden',
    total_customers: 'Gesamt Kunden',
    new_customers: 'Neue Kunden',
    returning_customers: 'Rückkehrende Kunden',
    orders: 'Bestellungen',
    total_orders: 'Total Bestellungen',
    pickup_orders_rate: 'Abholaufträge',
    delivery_orders_rate: 'Lieferaufträge',
    other_orders_rate: 'Andere Aufträge',
    average_order_value: 'Durchsch. Bestellwert',
    google_reviews: 'Neue Google-Bewertungen',
    average_customer_lifetime_value: 'Kundenwert',
    rating: 'Bewertung',
    average_rating: 'Durchschnitt',
    rates: '# Bewertungen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
