import * as React from 'react';

export default function ChevronLeftIcon({
  width, height, className, style,
  color,
}) {
  const _width = width ?? 10;
  const _height = height ?? 18;
  const _color = color ?? '#161616';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M8.59987 18C8.47275 18.0002 8.34704 17.9734 8.23111 17.9213C8.11518 17.8691 8.0117 17.7929 7.92755 17.6976L0.727351 9.59737C0.580903 9.43275 0.5 9.22009 0.5 8.99975C0.5 8.77942 0.580903 8.56676 0.727351 8.40214L7.92755 0.301915C8.00603 0.213625 8.10113 0.141658 8.20742 0.0901212C8.31372 0.0385848 8.42912 0.00848904 8.54704 0.00155231C8.66497 -0.00538442 8.7831 0.0109737 8.89471 0.0496927C9.00631 0.0884117 9.10919 0.148733 9.19748 0.227213C9.28577 0.305693 9.35774 0.400795 9.40928 0.507088C9.46081 0.613382 9.49091 0.728785 9.49785 0.846709C9.50478 0.964634 9.48842 1.08277 9.44971 1.19437C9.41099 1.30597 9.35066 1.40886 9.27218 1.49715L2.603 8.99975L9.27218 16.5024C9.38773 16.6319 9.46331 16.7921 9.48981 16.9637C9.5163 17.1352 9.49257 17.3107 9.42148 17.4691C9.35039 17.6275 9.23498 17.7619 9.08919 17.8561C8.94339 17.9503 8.77345 18.0003 8.59987 18Z"
        fill={_color}
      />
    </svg>
  );
}
