import Billing from './billing';
import Buttons from './buttons';
import Contract from './contract';
import Dashboard from './dashboard';
import DineIn from './dine-in';
import Filters from './filters';
import Form from './form';
import ImageCropper from './image-cropper/ImageCropperIndex';
import Layout from './layout';
import Locations from './locations';
import LoyaltyProgram from './loyalty-program';
import LoyaltyStats from './loyalty-stats';
import Marketing from './marketing';
import Menu from './menu';
import Onboarding from './onboarding';
import OpeningHours from './opening-hours';
import PDF from './pdf';
import PromoCode from './promo-code';
import Reservations from './reservations';
import ScheduleExceptions from './schedule-exceptions';
import Subscription from './subscription';
import Takeout from './takeout';
import Team from './team';
import Verification from './verification';
import CompanySelect from './CompanySelect';
import ClickToCopy from './ClickToCopy';

export default {
  Billing,
  Buttons,
  Contract,
  Dashboard,
  DineIn,
  Filters,
  Form,
  ImageCropper,
  Layout,
  Locations,
  LoyaltyProgram,
  LoyaltyStats,
  Marketing,
  Menu,
  Onboarding,
  OpeningHours,
  PDF,
  PromoCode,
  Reservations,
  ScheduleExceptions,
  Subscription,
  Takeout,
  Team,
  Verification,
  CompanySelect,
  ClickToCopy,
};
