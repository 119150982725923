import React, { useMemo } from 'react';
import {
  CBadge,
} from '@coreui/react';
import { collect } from 'collect.js';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { Drawer } from 'antd';
import BackButton from '../../components/buttons/BackButton';
import UserIcon from '../../resources/assets/icons/UserIcon';
import { ORDER_PAYMENT_STATUSES, FULFILLMENT_STATUSES } from '../../services/exports/Constants';
import Divider from '../../components/appearence/Divider';
import useHelpers from '../../hooks/useHelpers';
import useScreenType from '../../hooks/useScreenType';

export default function DineInOrderDetailsModal({
  toggleModal,
  data,
}) {
  const { formatCurrency, formatTime } = useHelpers();

  const { isDesktop } = useScreenType();

  const subOrders = collect(data?.sub_orders)
    .where('fulfillment_status', '!=', FULFILLMENT_STATUSES.rejected)
    .toArray();
  const canceledSubOrders = collect(data?.sub_orders)
    .where('fulfillment_status', FULFILLMENT_STATUSES.rejected)
    .toArray();

  const isPaid = useMemo(
    () => data?.payment_status === ORDER_PAYMENT_STATUSES.paid,
    [data?.payment_status],
  );
  const unpaidAmount = useMemo(() => {
    const amount = (data?.total_amount ?? 0) - +collect(data?.payments).sum('total_amount');

    return amount < 0 ? 0 : amount;
  }, [data?.sub_total, data?.payments]);

  const renderHeader = useMemo(() => (
    <div className="w-100 px-3 mt-4">
      <BackButton onClick={toggleModal} />
      <div className="mt-3">
        <h5>
          {'Table '}
          {data?.party?.table?.number}
          {', Group '}
          {data?.party?.id}
        </h5>
        <div className="website-text d-flex mt-1">
          <UserIcon className="mr-2 my-auto" />
          {data?.party?.users?.length}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex">
          <div className="d-flex flex-column mr-4">
            <h5
              className={classnames({
                'text-red': !isPaid,
                'text-primary': isPaid,
              })}
            >
              {formatCurrency(unpaidAmount)}
            </h5>
            <p
              className={classnames('website-text mt-2', {
                'text-red': !isPaid,
                'text-primary': isPaid,
              })}
            >
              left to pay
            </p>
          </div>
          <div className="d-flex flex-column">
            <h5>{formatCurrency(data?.total_amount)}</h5>
            <p className="website-text mt-2">total order</p>
          </div>
        </div>
      </div>
    </div>
  ), [data]);

  const renderPaymentActions = (payment) => (
    <CBadge
      color="orange"
      className="website-text text-white p-2"
    >
      {formatCurrency(
        collect(payment?.refunds).sum('total_amount'),
      )}
      {' refunded'}
    </CBadge>
  );

  const renderMembers = useMemo(() => data?.party?.users?.length > 0 && (
    <div className="w-100 mt-3">
      <h5 className="px-3">Members</h5>
      <div className="overflow-x-scroll mt-2">
        <table className="table w-100 bg-white flex border-collapse table-striped rounded-none">
          <thead>
            <tr>
              <th className="secondary pl-3">Name</th>
              <th className="secondary">Phone</th>
              <th className="secondary pr-3">Email</th>
            </tr>
          </thead>
          <tbody className="align-middle">
            {data?.party?.users?.map((item) => (
              <tr
                key={`party-${data?.party?.id}-member-${item?.id}`}
              >
                <td className="website-text-mini pl-3">{item?.name}</td>
                <td className="website-text-mini">{item?.phone_number}</td>
                <td className="website-text-mini pr-3">{item?.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ), [data?.party?.users]);

  const renderPayments = useMemo(() => data?.payments?.length > 0 && (
    <div className="mt-3">
      <h5 className="text-primary px-3">Paid</h5>
      <div className="overflow-x-scroll mt-2">
        <table className="table w-100 bg-white flex border-collapse table-striped">
          <thead>
            <tr>
              <th className="secondary ps-3">Time</th>
              <th className="secondary">
                <UserIcon />
              </th>
              <th className="secondary">Tip</th>
              <th className="secondary pe-3">Total</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
            </tr>
          </thead>
          <tbody className="align-middle">
            {data?.payments?.map((item) => (
              <tr
                className="cursor-pointer"
                key={`dine-in-order-${data?.id}-payment-${item?.id}`}
              >
                <td className="website-text-mini ps-3">{formatTime(item?.paid_at)}</td>
                <td className="website-text-mini">{item?.user?.first_name}</td>
                <td className="website-text-mini">{formatCurrency(item?.tip)}</td>
                <td className="website-text-mini">
                  {formatCurrency(item?.total_amount)}
                </td>
                <td className="website-text-mini pe-3">
                  <div className="d-flex justify-content-end">{renderPaymentActions(item)}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ), [data?.payments]);

  const renderSubOrders = useMemo(() => subOrders.length > 0 && (
    <div>
      <h5 className="px-3">Orders</h5>
      <div className="overflow-x-scroll mt-2">
        <table className="table w-100 text-default bg-white flex border-collapse">
          <tbody className="align-middle">
            {subOrders.map((item) => (
              <>
                <tr
                  className="cursor-pointer bg-gray-50"
                  key={`dine-in-order-${data?.id}-sub-order-${item?.id}`}
                >
                  <td className="website-text-mini ps-3">
                    <h6>{item?.number}</h6>
                  </td>
                  <td className="website-text-mini">
                    <div className="d-flex">
                      <CBadge color="orange" className="bg-orange website-text-mini text-white mr-2 my-auto">
                        {moment().diff(item?.created_at, 'minutes')}
                        m
                      </CBadge>
                      <p className="website-text-mini my-auto">
                        {formatTime(item?.created_at)}
                      </p>
                    </div>
                  </td>
                  <td className="website-text-mini text-right">{formatCurrency(item?.total_amount)}</td>
                </tr>
                {item?.line_items?.map((lineItem, index) => (
                  <tr
                    className="pb-0 border-none"
                    key={`dine-in-order-${data?.id}-sub-order-${item?.id}-line-item-${lineItem?.id}`}
                  >
                    <td colSpan={2} className="website-text ps-3">
                      <div className="d-flex flex-column">
                        <div className="website-text-mini mb-1">
                          {lineItem?.quantity}
                          x
                          {' '}
                          {lineItem?.name}
                        </div>
                        <ul
                          className={classnames({
                            'mb-0': index !== (item?.line_items?.length ?? 0) - 1,
                          })}
                        >
                          {lineItem?.modifiers?.map((modifier) => (
                            <li
                              key={`dine-in-order-${data?.id}-sub-order-${item?.id}-line-item-${lineItem?.id}-modifier-${modifier?.id}`}
                              className="website-text-mini"
                            >
                              {modifier?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </td>
                    <td className="website-text-mini secondary align-baseline">
                      <div className="d-flex justify-content-end">{formatCurrency(lineItem?.actual_price)}</div>
                    </td>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ), [data, subOrders]);

  const renderCancelledSubOrders = useMemo(() => canceledSubOrders.length > 0 && (
    <div>
      <h5 className="px-3 text-danger">Canceled orders</h5>
      <div className="overflow-x-scroll mt-2">
        <table className="table bg-white flex border-collapse">
          <tbody className="align-middle">
            {canceledSubOrders.map((item) => (
              <>
                <tr
                  className="bg-gray-50"
                  key={`dine-in-order-${data?.id}-sub-order-${item?.id}`}
                >
                  <td className="website-text-mini ps-3">
                    <h6>{item?.number}</h6>
                  </td>
                  <td className="website-text-mini">
                    <div className="d-flex justify-content-end">
                      <div className="line-through mr-2">
                        {formatCurrency(item?.total_amount)}
                      </div>
                      <div className="text-danger">Cancelled</div>
                    </div>
                  </td>
                </tr>
                {item?.line_items?.map((lineItem, index) => (
                  <tr
                    className="pb-0 border-none"
                    key={`dine-in-order-${data?.id}-sub-order-${item?.id}-line-item-${lineItem?.id}`}
                  >
                    <td className="website-text ps-3">
                      <div className="d-flex flex-column">
                        <p className="website-text-mini mb-1">
                          {lineItem?.quantity}
                          x
                          {' '}
                          {lineItem?.name}
                        </p>
                        <ul
                          className={classnames({
                            'mb-0': index !== (item?.line_items?.length ?? 0) - 1,
                          })}
                        >
                          {lineItem?.modifiers?.map((modifier) => (
                            <li
                              className="website-text-mini"
                              key={`dine-in-order-${data?.id}-sub-order-${item?.id}-line-item-${lineItem?.id}-modifier-${modifier?.id}`}
                            >
                              {modifier?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </td>
                    <td className="website-text-mini secondary align-baseline">
                      <div className="d-flex justify-content-end">{formatCurrency(lineItem?.actual_price)}</div>
                    </td>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ), [data, canceledSubOrders]);

  return (
    <Drawer
      open
      alignment="center"
      scrollable
      width={window.innerWidth * (isDesktop ? 0.5 : 1)}
      onClose={toggleModal}
    >
      <div className="overflow-y-scroll">
        {renderHeader}
        {renderMembers}
        {renderPayments}
        <Divider className="my-3" />
        {renderSubOrders}
        {renderCancelledSubOrders}
      </div>
    </Drawer>
  );
}
