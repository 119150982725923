import { ORDER_METHODS } from '../../../../../services/exports/Constants';

export default {
  empty_list: 'Noch keine Artikel in diesem Menü. Füge mindestens einen neuen Artikel hinzu.',
  combo_items: 'Artikel',
  fields: {
    number: '#',
    image: 'Bild',
    name: 'Name',
    prices: 'Preise',
    description: 'Beschreibung',
    available: 'Verfügbar',
    required: 'Erforderlich',
    products: 'Artikel: {{count}}',
  },
  labels: {
    visible: 'Sichtbar im Menü',
    hidden: 'Alle versteckt',
  },
  order_method_prices: {
    base: 'Basis',
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ORDER_METHODS.room_service]: 'Tisch/Zimmer-service',
    [ORDER_METHODS.dine_in]: 'Dine in',
  },
  buttons: {
    delete_products_one: 'Artikel entfernen',
    delete_products_other: 'Artikel entfernen',
    add_products: 'Artikel hinzufügen',
    show_products: 'Kategorie aufzeigen',
    hide_products: 'Kategorie schließen',
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
  },
  modals: {
    delete_combo_item: {
      title: 'Menü löschen {{name}}?',
    },
  },
  toasts: {
    combo_item_got_deleted: 'Menü wurde gelöscht',
    failed_to_delete_combo_item: 'Menü löschen fehlgeschlagen',
    something_went_wrong: 'Etwas ist schief gelaufen',
  },
};
