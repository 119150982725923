import ApiResourceManager from '../ApiResourceManager';

export default class UnpaidOrdersManager extends ApiResourceManager {
  get = (page) => this.request({
    method: 'GET',
    url: '/unpaid-orders',
    params: {
      page,
    },
  });
}
