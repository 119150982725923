export default {
  title: 'Alle Kunden',
  fields: {
    name: 'Name',
    phone: 'Telefon',
    recent_order: 'Letzte Bestellung',
    total_orders: 'Total Bestellungen',
    current_points: 'Aktuelle Punkte',
    google_review: 'Google Review',
  },
  empty_list: 'Noch keine Kunden',
  yes: 'Ja',
  no: 'Nein',
  buttons: {
    export: 'Exportieren',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
