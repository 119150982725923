export default {
  form: {
    address: {
      label: 'Straße',
      placeholder: 'Straße',
    },
    zip_code: {
      label: 'PLZ',
      placeholder: 'PLZ',
    },
    city: {
      label: 'Stadt',
      placeholder: 'Stadt',
    },
    start_time: {
      label: 'Startzeit',
      placeholder: 'z.B. 11:00',
    },
    name: {
      label: 'Name des Standorts (optional)',
      placeholder: 'z.B. Am Marktplatz',
      tooltip: 'Die Eingabe eines Standortnamens kann Kunden helfen, den Standort zu identifizieren, z. B. "Neben dem Fernsehturm"',
    },
  },
  buttons: {
    add_location: 'Standort hinzufügen',
  },
};
