export default {
  title: 'Tabellennummern hinzufügen',
  fields: {
    select_all: 'Alle auswählen',
    table_number: 'Tabellennummer',
    edit: 'Bearbeiten',
  },
  buttons: {
    edit: 'Bearbeiten',
    create_tables: 'Tabellen erstellen',
    delete_tables: 'Tabellen löschen',
    download_qr_codes: 'QR-Codes herunterladen',
  },
  modals: {
    delete_tables: {
      title: 'Ausgewählte Tabellen löschen',
      description: 'Alle ausgewählten Tabellennummern und -namen werden gelöscht.',
      buttons: {
        yes: 'Ja',
        no: 'Nein',
      },
    },
  },
  toasts: {
    table_creation_disabled: 'Die Erstellung von Tischen ist deaktiviert, da die Einrichtung der Dine-In Funktion noch nicht abgeschlossen ist',
    failed_to_load_data: 'Das Laden von Tischdaten ist fehlgeschlagen',
    failed_to_download_qr_codes: 'Download von QR-Codes fehlgeschlagen',
  },
};
