export default {
  sections: {
    dine_in_orders: {
      title: 'Tisch Bestellungen',
      fields: {
        dine_in_enabled: {
          label: 'Tischbestellungen aktiv',
        },
        last_order_gap_dine_in: {
          label: 'Letzt mögliche <strong>Tischbestellung</strong> (Minuten vor Schliessung)',
          option: '{{value}} min',
        },
      },
    },
  },
  buttons: {
    save: 'Speichern',
  },
};
