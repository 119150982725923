export default {
  tabs: {
    tables: 'Tische',
    order_details: 'Bestellinfo',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
